<script>
import AppConfig from "@/common/config/app.config.json";
import { alert, request } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";

export default {
  name: "ExportTicket",
  mixins: [alert, loader, request],
  props: {
    subid: {
      required: true,
    },
  },
  data() {
    return {
      model: {
        title: "",
        notes: "",
      },
    };
  },
  methods: {
    onClose: function () {
      this.$emit("close", true);
    },
    onCreate: function () {
      this.loading();
      this.API.post(this.URL.modules.ticket.export, {
        ...this.model,
        subid: this.subid,
      })
        .then((data) => {
          console.warn(data);
        })
        .catch(({ response }) => {
          this.$swal("Error!", response.data.message, "error");
        })
        .finally(() => {
          this.loading(false);
        });
    },
  },
  computed: {
    isModuleOn() {
      return AppConfig.modules.includes("export_ticket");
    },
  },
};
</script>

<template>
  <div class="additional-info-window" v-if="isModuleOn">
    <div class="additional-info__header">
      <img src="img/left-arrow-grey.svg" alt="left" @click="onClose" />
      Export to Ticket
    </div>
    <div class="additional-info__body">
      <form @submit.stop.prevent="onCreate" method="post">
        <div class="form-group">
          <label>Title</label>
          <input
            type="text"
            placeholder="Enter title"
            v-model="model.title"
            required
          />
        </div>
        <div class="form-group">
          <label>Notes</label>
          <textarea
            placeholder="Enter notes"
            class="shadow-none border border-info"
            v-model="model.notes"
          ></textarea>
        </div>
        <div class="d-flex justify-content-between">
          <button type="submit">Submit</button>
          <button type="button" class="btn-secondary" @click="onClose">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped></style>
